
.checkout-loader {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-loader span {
  background: no-repeat url('../images/loader.gif');
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: cover;
}