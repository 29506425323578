.order-placed-address-btn {
    background: #189200;
    border: none;
    padding: 8px 20px;
    color: white;
    font-weight: 900;
    font-size: 13px;
    border-radius: 4px;
}

.background-white {
    background: #ffffff;
}

.order-status-serenade {
    border-radius: 8px;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

.font-weight {
    font-size: 14px;
}

.background-pink {
    background-color: #fce9ea;
}

.order-placed-parent {
    min-height: 100vh;
    padding-bottom: 53px;
}