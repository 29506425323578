.page-reviews * {
  box-sizing: border-box; }
.page-reviews footer {
  margin-bottom: 0;
  background: #efefef; }

.cs-top-banner {
  padding: 0 16px;
}
  .cs-top-banner .rating-recommends {    
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
  }
    .cs-top-banner .rating-recommends .rating-section {
      display: inline-block;
      width: 45%;      
      text-align: left;
      /* background: url("../../../images/review-leaf.png") no-repeat, url("../../../images/review-leaf-right.png") no-repeat right 0; */
      /* padding: 0 30px 30px 30px;
      background-size: 25px;  */
    }
    .cs-top-banner .rating-recommends .rating-section .rating-recommends-result {
      padding: 0 0 0 36px;
      background: url("../../../images/starRating.svg") no-repeat;
      font-family: 'Lato', sans-serif;
      color: #1c2120;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .cs-top-banner .rating-recommends .recommends-section {
      display: inline-block;
      width: 55%;            
      /* background: url("../../../images/like_icon.svg") no-repeat 0 -3px;
      background-size: 43px;
      padding: 0 0 0 50px; */      
    }
    .cs-top-banner .rating-recommends .recommends-section .rating-recommends-result {
      padding: 0 0 0 26px;
      background: url("../../../images/thumbUpRecomend.svg") no-repeat;
      font-family: 'Lato', sans-serif;
      color: #1c2120;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

.vertical_line_reivews{
  height: 1px;
  /* margin: 24px 0; */
  margin: 24px 0 12px;
  background: #edf1f7;
}

  .cs-top-banner .table-cell {
    margin: 0px 0 20px 0;
    text-align: center;
    background: #f9f9f9;
    border: 1px solid #ccc;
    padding: 20px 0 20px 0; }
    .cs-top-banner .table-cell .c6 {
      font-size: 18px; }
    .cs-top-banner .table-cell a {
      padding: 7px 25px 8px 25px;
      border: 1px solid #46875B;
      border-radius: 3px;
      background-color: #58996D;
      color: #ffffff;
      margin-top: 0;
      display: inline-block; }

.review-list {
  display: inline-block;
  background: rgba(237, 241, 247, 0.2);
  width: 86%;
  max-width: 296px;
  margin: 0 16px 0 0;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  white-space: normal;
  vertical-align: top;
  border: 0.5px solid rgba(28, 33, 32, 0.2);
}
  .review-list h3 {
    margin: 0 0 16px 0;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .review-list h3 span{        
    font-size: 12px;
    color: rgba(28, 33, 32, 0.8);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 12px;
    text-transform: capitalize;
  }

  .rating_review_recomend{}
  .rating_review_recomend h6{
    margin: 0 0 5px 0;
    font-family: 'Lato', sans-serif;
    color: rgba(28, 33, 32, 0.6);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .recommended{
    display: inline-block;
    padding-left: 12px;
    vertical-align: text-bottom;
    border-left: 1px solid #ddd;
    margin-left: 10px;
    line-height: 12px;
  }
  .recommended span{
    font-family: 'Lato', sans-serif;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #199100;
  }

  .review-list .ratingstar {
    display: inline-block; 
  }
    .review-list .ratingstar sup {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-size: 85px auto;
      background-image: url("../../../images/star-sprite.png");
      position: relative;
      top: 5px; }
    .review-list .ratingstar .fill-star {
      background: url("../../../images/fill_star.svg") no-repeat 0px 0px;
      background-size: 10px;
      width: 10px;
      height: 10px;
      margin-right: 1px;
    }
    .review-list .ratingstar .unfill-star {
      background: url("../../../images/unfill_star.svg") no-repeat 0px 0px;
      background-size: 10px;
      width: 10px;
      height: 10px; }
  .review-list .rating-desc {
    font-size: 15px;
    line-height: 27px;
    color: #181818;
    margin-top: 10px; 
    padding: 0;
  }
  .review-list .rating-desc .readMore{    
    text-decoration: underline;
    font-weight: 400;
    color: #2862c8;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }

  .review-list .rating-desc::first-letter {
    text-transform: capitalize; }
  .review-list .c9 .author-name {
    font-size: 18px;
    font-weight: 700;
    display: block;
    text-transform: capitalize;
    color: #444;
    font-family: 'Playfair Display', serif;
    padding-bottom: 5px; }
  .review-list .recommanded {
    background: url("../../../images/thumbs-up-hand-symbol.svg") no-repeat left 0px;
    background-size: 20px; }
    .review-list .recommanded .recommanded-icon {
      padding-left: 25px; }

.recommand-service {
  /* background: url("../../../images/like_icon_right.svg") no-repeat 102% 10px;
  background-size: 45px;  */
}

.view_footer_center{
  text-align: center;
}
/*All Reviews*/
.view-review-list{
  padding: 16px 0px 16px 16px;
  margin-bottom: 60px;
}
.view-review-list .view-filters {
  background: #efefef none repeat scroll 0 0;
  display: inline-block;
  margin: 0px 0 0;
  padding: 5px 0;
  text-align: left;
  width: 100% !important; }
  .view-review-list .view-filters .views-exposed-form {
    display: inline-block;
    float: right; }
    .view-review-list .view-filters .views-exposed-form .views-exposed-widgets {
      margin-bottom: 0; }
      .view-review-list .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-order_rating {
        padding: 5px 0 5px 4px; }
        .view-review-list .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-order_rating label {
          font-size: 0; }
      .view-review-list .view-filters .views-exposed-form .views-exposed-widgets select#edit-order-rating {
        background-position: 51px 10px;
        padding: 3px 20px 2px 5px;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: #ffffff url("../../../images/arrow-down.png") no-repeat scroll 93.5% 45%/8px auto;
        border: 1px solid #ccc;
        color: #737373;
        font-size: 12px;
        line-height: 20px;
        padding-left: 5px;
        padding-right: 20px; }
.view-review-list h2 {
  text-align: center;
  font-size: 24px; }
.view-review-list .view-content {
  white-space: nowrap;
  text-align: left;
  overflow-x: scroll;
  width: 100%;
  background: #ffffff;
}
  .view-review-list .view-content .review-list {
    text-align: left; 
    min-height: 224px;
  }
.view-review-list .prod-rating {
  width: 44%;
  display: inline-block;
  margin-right: 5%; 
}
  .view-review-list .prod-rating .padding-bottom-0 {
    padding-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .view-review-list .prod-rating .rating .product-rating-1, .view-review-list .prod-rating .rating .product-rating-2, .view-review-list .prod-rating .rating .product-rating-3, .view-review-list .prod-rating .rating .product-rating-4, .view-review-list .prod-rating .rating .product-rating-5 {
    background-image: url("../../../images/star-sprite.png");
    background-size: 85px auto;
    font-size: 0;
    padding: 10px 43px;
    width: auto;
    display: inline-block;
    margin-bottom: 10px; }
  .view-review-list .prod-rating .rating .product-rating-2 {
    background-position: 0 65px; }
  .view-review-list .prod-rating .rating .product-rating-1 {
    background-position: 0 45px; }
  .view-review-list .prod-rating .rating .product-rating-3 {
    background-position: 0 -49px; }
  .view-review-list .prod-rating .rating .product-rating-4 {
    background-position: 0 115px; }
  .view-review-list .prod-rating .rating .product-rating-5 {
    background-position: 0 140px; }

/*All Reviewd Ends*/
/*Edit-Review*/
.view-review-ref-with-order ul li {
  margin-left: 0 !important; }
  .view-review-ref-with-order ul li .views-field-nothing {
    color: #737373;
    font-size: 13px;
    width: 95%;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px; }

.add_review {
  padding: 10px 2% 0 2%; }
  .add_review .submit #edit-submit {
    background: #be324c;
    border: 0 none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    margin: 2% 2% 2% 0;
    padding: 8px 20px;
    width: 100%; }
  .add_review .my-review-product {
    width: 48%;
    text-align: center;
    display: inline-block; }
    .add_review .my-review-product .product-iamges {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .add_review .ratestar {
    display: inline-block; }
  .add_review label {
    font-weight: normal;
    margin: 10px 0 10px 0; }
    .add_review label sup {
      color: red; }
  .add_review .form_recomd {
    display: inline-block;
    width: 100%; }
    .add_review .form_recomd label {
      width: 195px;
      display: inline-block; }
    .add_review .form_recomd .colh-lg-8 {
      display: inline-block;
      float: right;
      margin-right: 5px;
      text-align: right; }
      .add_review .form_recomd .colh-lg-8 .form-type-radio {
        display: inline-block; }
      .add_review .form_recomd .colh-lg-8 .form-radios {
        width: 145px;
        display: inline-block; }
        .add_review .form_recomd .colh-lg-8 .form-radios input {
          width: auto;
          padding: 0 5px 0 5px;
          margin: 2px 5px 0 5px; }
  .add_review .form_rate {
    width: 100%;
    float: left; }
    .add_review .form_rate .col-lg-4 {
      width: 195px;
      display: inline-block; }
    .add_review .form_rate .ratestar {
      float: right;
      margin-right: 0%; }
  .add_review .colh-lg-8 {
    display: inline;
    float: none;
    width: auto; }
    .add_review .colh-lg-8 input, .add_review .colh-lg-8 textarea {
      border: 1px solid #ccc;
      border-radius: 4px;
      float: left;
      box-sizing: border-box;
      padding: 10px 0 10px 10px;
      width: 100%; }
  .add_review .ratestar > label {
    color: #ddd;
    float: right; }
  .add_review label.option {
    display: inline;
    font-weight: normal; }
  .add_review .ratestar > label::before {
    margin: 2px;
    font-size: 1em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005"; }
  .add_review .ratestar:not(:checked) > label:hover {
    color: #ffb204; }
  .add_review .ratestar > input:checked ~ label {
    color: #ffb204; }
  .add_review .ratestar > input:checked + label:hover, .add_review .ratestar > input:checked ~ label:hover {
    color: #ffb204; }
  .add_review .ratestar > input, .add_review .add_review .grippie {
    display: none; }

/*Edit-Review*/
.block-fa-reviews {
  display: block;
  width: 90%;
  margin: 25px auto; }
  .block-fa-reviews .item-list ul li {
    padding: 0;
    display: inline-block;
    border-right: none;
    margin: 15px 0 0 0;
    text-align: left;
    width: 48%; }
    .block-fa-reviews .item-list ul li a {
      color: #006FB4; }
    .block-fa-reviews .item-list ul li.first {
      padding-left: 0; }
    .block-fa-reviews .item-list ul li.last {
      padding-right: 0;
      border-right: none; }

.product-reviews {
  width: 100%;
  padding: 16px 0; 
  background: #ffffff;
  border-bottom: 2px solid #ccc;
}
.review_box{
  padding: 0 16px;
  position: relative;
}
  .product-reviews h2 {
    font-size: 20px;
    color: #1c2120;
    margin-bottom: 24px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    width: 100%;
  }
.product-reviews h2 .review_product{
  margin: 8px 0 0;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: rgba(28, 33, 32, 0.7);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.item-list .pager li {
  padding: 3px 8px 3px 8px;
  border: 1px solid #ccc;
  margin: 3px !important;
  position: relative; }
.item-list .pager .pager-previous a {
  background-color: transparent;
  background-image: url(../../../images/arrow-next.png);
  background-position: 13px 10px;
  background-repeat: no-repeat;
  border: none;
  font-size: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 2px 17px;
  background-size: 8px;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(180deg);
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px; 
}
.item-list .pager .pager-next a {
  background-color: transparent;
  background-image: url(../../../images/arrow-next.png);
  background-position: 10px 1px;
  background-repeat: no-repeat;
  border: none;
  float: right;
  font-size: 0;
  margin-right: 0;
  margin-top: 6px !important;
  padding: 10px 0 18px 32px;
  margin-left: 9px;
  background-size: 8px;
}

/*# sourceMappingURL=m-reviews.css.map */
ul, ol, li, p, h6, h4, h3, h2 {
    margin: 0;
    padding: 0;
}
h2 {
  font-weight: normal;
}

.product-reviews p {
  padding-bottom: 10px;
}

.cardMsgModal{
  /* height: auto !important; */
  /* transform: translateY(70%); */
}
.full_reivew_nsg{
  padding: 0 16px;
}
.full_reivew_nsg p{
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1c2120;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0 0 20px 0;
}
.full_reivew_nsg p:first-letter {
  text-transform: uppercase;
}
.closeBtnReview{
  text-align: center;
}
.closeBtnReview button{
  color: #1c2120;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  padding: 7px 15px;
  left: 0;
  right: 0;
  font-size: 16px;
  position: relative;
  z-index: 9999;
  bottom: 0;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0;
  background: #fff;
  display: inline-block;
}

.write_reivew_parent{
  padding: 15px;
  border-top: 2px solid #ccc;
}
.write_reivew_parent h3{
  text-align: center;
  margin: 0 0 15px 0;
}

.write_reivew{
  text-align: center;
}
.write_reivew .review-link{
  display: inline-block;
  border: 1px solid #199100;
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #199100;
  text-align: center;
  padding: 9px 14px;
  text-decoration: none;
  border-radius: 0;
  font-weight: 700;
  cursor: pointer;
}

/*ssr detail page desktop design*/

@media only screen and (min-width:767px){

  .reviews-d .view-review-list .view-content{ 
    white-space: normal;
    overflow-x: hidden;
  }

  .cs-top-banner .rating-recommends .rating-section,.cs-top-banner .rating-recommends .recommends-section{
    width: auto;
    margin-right: 20px;
  }

  .cs-top-banner .rating-recommends .recommends-section .rating-recommends-result {
    background: url("../../../images/recomended.svg") no-repeat;
    padding: 10px 0 0px 40px;
    margin-top: -8px;
  }

  .reviews-d .view-review-list .view-content .review-list{ 
    width: 100%;
    max-width: none;
    min-height: auto;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    border-top: 1px dotted #9b9b9b;
    box-shadow: none;
    margin-bottom: 15px;
  }

  .reviews-d .view-review-list .view-content .more-review-desktop{
    display: none;
  }

  .product-reviews h2{
    font-size: 16px;
  }

  .listPageReviewDyn .review_box h2{
    text-align: center;
    font-family: playfair;
    font-size: 26px;
    width: auto;
    display: inline-block;
    position: relative;
  }

  .listPageReviewDyn .review_box h2:before,  .listPageReviewDyn .review_box h2:after{
    content: "";
    position: absolute;
    height: 12px;
    border-bottom: 1px solid #ccc;
    top: 0;
    width: 425px;
  }

  .listPageReviewDyn .review_box h2:before{
    right: 100%;
    margin-right: 15px;
  }

  .listPageReviewDyn .review_box h2:after{
    left: 100%;
    margin-left: 15px;
  }

  .product-reviews h2 .review_product{
    /* display: inline-block; */
    font-size: 16px;
    font-weight: bold;
    color: #1c2120;
    margin-left: 4px;
  }

  .vertical_line_reivews{
    display: none;
  }

  .view-review-list {
    padding: 35px 0px 16px 16px;
    margin-bottom: 20px;
}

.reviews-d .review-list h3 {
  font-size: 15px;
}

.reviews-d .review-list .ratingstar .fill-star{
  background-size: 10px;
  width: 12px;
  height: 9px;
}

.reviews-d .review-list .ratingstar .unfill-star{
  background-size: 10px;
  width: 12px;
  height: 9px;
}

.review-list .rating-desc{
  font-size: 13px;
}

.product-reviews{
  border-bottom:none;
  padding-bottom: 40px;
}

.cs-top-banner .rating-recommends .rating-section .rating-recommends-result{
  font-weight:bold;
  font-size: 16px;
}

.cs-top-banner .rating-recommends .recommends-section .rating-recommends-result{
  font-size: 16px;
}

.review_box {
  position: relative;
}

.listPageReviewDyn .review_box {
  overflow: hidden;
}

.listPageReviewDyn .rating-recommends{
  justify-content: center;
}

.listPageReviewDyn .cs-top-banner .rating-recommends .rating-section .rating-recommends-result {
  font-size: 25px;
  background: url(https://img.floweraura.com/sites/all/themes/facd7/images/revamp/review-leaf.png) no-repeat,url(https://img.floweraura.com/sites/all/themes/facd7/images/revamp/review-leaf-right.png) no-repeat right 0;
  background-size: 25px;
  padding: 0 30px 30px 30px;
}

.listPageReviewDyn .cs-top-banner .rating-recommends .recommends-section .rating-recommends-result {
  font-size: 25px;
}

.listPageReviewDyn .view-review-list .view-content .view_footer_center a{
    font-size: 16.5pt;
    border: 1.5px solid #DCDCDC;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
    text-decoration: none;
    padding: 10px 45px 10px 45px;
    color: #535353;
    margin: 23px 0 0 0;
    display: inline-block;
    margin-bottom: 10px;
}

.reviews-d .product-reviews .view-footer {
  right: auto;
  top: initial;
  bottom: 28px;
}

.reviews-d .view-footer .view_footer_center a{
    font-size: 14px;
    text-align: left;
    color: #4a90e2;
    width: auto;
    border: none;
}

.product-reviews.listPageReviewDyn{
  width: 90%;
  margin: 30px auto;
  text-align: center;
  padding-bottom: 0px;
}
.listPageReviewDyn .view-review-list{
  padding-right: 0;
}
.listPageReviewDyn .view-review-list .view-content{
  overflow: hidden;
  white-space: normal;
}
.listPageReviewDyn .view-review-list .view-content .review-list{
  padding: 0 20px 0 20px;
  min-width: initial;
  width: 96%;
  max-width: initial;
  height: 224px;
  overflow: hidden;
  margin: 0;
  box-shadow: none;
}

}
.product-reviews .view-footer {
    position: absolute;
    right: 16px;
    top: 0px;
}
.view-footer .view_footer_center a{
    /* color: #2862c8; */
    display: inline-block;
    width: 70px;
    border: 1px solid #199100;
    font-family: Lato,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #199100;
    text-align: center;
    padding: 5px 0;
    text-decoration: none;
    border-radius: 0;
    font-weight: 700;
}


.fivestar-widget-static{
  margin-top: 10px;
}
.reviewPoint{
  display: inline-block;
  background: url("../../../images/fill_star.svg") no-repeat 0px 0px; 
  background-position: 4px 4px;
  background-size: 19px;
  padding: 5px 8px 6px 30px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1c2120;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.starRatingBG{
  position: relative;
  /* padding-left: 20px; */
}
.total-reviews, .total-reviews:hover  {
  color: #4a90e2;
  border-bottom: 1px solid;
  margin-left: 14px;
  vertical-align: top;
  cursor: pointer; 
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin-top: 4px;
display: inline-block;
}

.ReactModal__Body--open .ReactModalPortal .ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.5) !important;
}